import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import { styled } from '@mui/system';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import NotificationItem from '../NotificationItem/NotificationItem';
import { ApiClient, Notification } from '../../../../api';

const NotificationListStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '16px 0',
  marginTop: '8px',
  border: '1px solid #0000003B',
  maxHeight: 'calc(100vh - 160px)',
  overflowY: 'auto'
}));

export const NotificationList: React.FC = () => {
  const [loadMore, setLoadMore] = useState(false);

  const { data: initialData } = useQuery({
    queryFn: async () => {
      return ApiClient.dashboard.getDashboardNotifications({
        skip: 0,
        limit: 5
      });
    },
    queryKey: ['notification', 'initial'],
    placeholderData: keepPreviousData
  });

  const { data: moreData } = useQuery({
    queryFn: async () => {
      return ApiClient.dashboard.getDashboardNotifications({
        skip: 0,
        limit: 100
      });
    },
    queryKey: ['notification', 'more'],
    enabled: loadMore,
    placeholderData: keepPreviousData
  });

  const notifications = loadMore ? moreData : initialData;

  const handleShowMoreClick = () => {
    setLoadMore(true);
  };

  return (
    <NotificationListStyled data-testid="notification-list__component">
      <Typography variant="h6" fontSize="24px" px="16px" mb="8px">
        Notifications
      </Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {notifications?.items?.length ? (
          notifications?.items?.map((notification: Notification) => (
            <NotificationItem key={`notification-${notification.id}`} notification={notification} />
          ))
        ) : (
          <Box sx={{ margin: '16px auto', textAlign: 'center' }}>
            <Typography variant="body1">No notifications to show</Typography>
          </Box>
        )}
      </List>
      {!loadMore && !!notifications?.items?.length && (
        <Box bgcolor="rgba(255, 255, 255, 0.85)" px="16px">
          <Link component="button" variant="body2" underline="hover" fontWeight={600} onClick={handleShowMoreClick}>
            Show more
          </Link>
        </Box>
      )}
    </NotificationListStyled>
  );
};

export default NotificationList;
